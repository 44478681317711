import React from "react";
import { useEffect, useState } from "react";
import { binanceApiUrl } from "./binanceApi";

interface TickerStats {
  askPrice: number;
  askQty: number;
  bidPrice: number;
  bidQty: number;
  closeTime: number;
  count: number;
  firstId: number;
  highPrice: number;
  lastId: number;
  lastPrice: number;
  lastQty: number;
  lowPrice: number;
  openPrice: number;
  openTime: number;
  prevClosePrice: number;
  priceChange: number;
  priceChangePercent: number;
  quoteVolume: number;
  symbol: number;
  volume: number;
  weightedAvgPrice: number;
}

interface TickerStatsState {
  items: TickerStats[];
  hasErrors: boolean;
}

const initialState: TickerStatsState = { items: [], hasErrors: false };

function compareStats(a: TickerStats, b: TickerStats) {
  if (a.symbol < b.symbol) {
    return -1;
  }
  if (a.symbol > b.symbol) {
    return 1;
  }
  return 0;
}

export const TickerStats = () => {
  const url = binanceApiUrl + "/api/v3/ticker/24hr";
  const [statsValue, setStatsValue] = useState(initialState);

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((res: TickerStats[]) => {
        setStatsValue({ items: res, hasErrors: false });
        //console.log(statsValue);
      })
      .catch(() => setStatsValue({ items: [], hasErrors: true }));
  }, [url]);

  if (statsValue.items.length > 0)
    return (
      <div>
        <table>
          <thead style={{ color: "white" }}>
            <tr>
              <th style={{ textAlign: "left" }}>Symbol</th>
              <th>Price</th>
              <th>Change</th>
            </tr>
          </thead>
          <tbody style={{ color: "#DDDDDD" }}>
            {statsValue.items.sort(compareStats).map((item) => {
              return (
                <tr key={item.symbol}>
                  <td>{item.symbol}</td>
                  <td>{item.lastPrice}</td>
                  <td style={getPctStyle(item.priceChangePercent)}>
                    {item.priceChangePercent} %
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  return <div>LOADING...</div>;
};

const getPctStyle = (value: number): React.CSSProperties => {
  if (value >= 0) return { textAlign: "right", color: "green" };
  return { textAlign: "right", color: "red" };
};
