import React from "react";
import { useEffect, useState } from "react";
import { getDateText, getTimeText } from "./Localizer";
import { binanceApiUrl } from "./binanceApi";

interface BinanceTimeResponse {
  serverTime: number;
}

interface ShowTimeState {
  response?: BinanceTimeResponse;
  hasErrors: boolean;
}

const initialState: ShowTimeState = { hasErrors: false };

export function ShowTime() {
  const url = binanceApiUrl + "/api/v3/time";
  const [timeValue, setTimeValue] = useState(initialState);

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((res: BinanceTimeResponse) => {
        //console.log(res);
        setTimeValue({ response: res, hasErrors: false });
      })
      .catch(() => setTimeValue({ response: undefined, hasErrors: true }));
  }, [url]);

  if (timeValue.response)
    return (
      <div>
        <p>
          {getDateText(timeValue.response?.serverTime)}{" "}
          {getTimeText(timeValue.response?.serverTime)}
        </p>
      </div>
    );
  return <div></div>;
}
