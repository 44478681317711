import React from "react";
import "./App.css";
// import Calculator from "./components/Calculator";
import Container from "react-bootstrap/Container";
import { Jumbotron } from "react-bootstrap";
import { ShowTime } from "./components/ShowTime";
import { TickerStats } from "./components/TickerStats";

function App() {
  return (
    <Container className="p-3">
      <Jumbotron className="App-header">
        <h1 className="App-h1">reponen.net</h1>
        <p>Welcome to reponen.net!</p>
        <ShowTime></ShowTime>
        <TickerStats></TickerStats>
        {/* <Calculator></Calculator> */}
      </Jumbotron>
    </Container>
  );
}

export default App;
